import styled from 'styled-components'

export const ShopContainer = styled.div<{
  innerHeight: number | null
  hasPageCallout: boolean
}>`
  display: flex;
  height: ${({innerHeight, hasPageCallout}) =>
    `calc(${innerHeight ? `${innerHeight}px` : '100vh'} - 50px ${
      hasPageCallout ? '- 40px' : ''
    })`};
  background-color: var(--dls-backgound-color-100);
  padding-top: var(--dls-size-4);

  &[data-milestone='true'] {
    height: ${({innerHeight, hasPageCallout}) =>
      `calc(${innerHeight ? `${innerHeight}px` : '100vh'} - 50px - 115px ${
        hasPageCallout ? '- 40px' : ''
      })`};
  }

  @media (min-width: 500px) {
    margin: auto;
    height: ${({innerHeight, hasPageCallout}) =>
      `calc(${innerHeight ? `${innerHeight}px` : '100vh'} - 60px ${
        hasPageCallout ? '- 50px' : ''
      })`};

    &[data-milestone='true'] {
      height: ${({innerHeight, hasPageCallout}) =>
        `calc(${innerHeight ? `${innerHeight}px` : '100vh'} - 60px - 120px ${
          hasPageCallout ? '- 50px' : ''
        })`};
    }
  }

  .category-container {
    background-color: white;
    padding: 0 var(--dls-size-1);
    padding-bottom: var(--dls-size-32);
    flex-shrink: 0;
    width: 80px;
    overflow-y: auto;
    border-top-right-radius: var(--dls-size-8);
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .page-container {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    margin-left: var(--dls-size-4);
    border-top-left-radius: var(--dls-size-8);
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media screen and (min-width: 768px) {
    .category-container {
      width: var(--dls-size-112);
      padding: 0 var(--dls-size-12);
      padding-bottom: var(--dls-size-32);
    }
  }
`

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  padding: var(--dls-size-16);
  border-bottom: 1px solid var(--dls-backgound-color-100);
  overflow-x: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
