import {Typography, TypographyVariants} from '@web-components'
import {StyledCheckboxContainer} from './CheckboxInput.styles'
import {CheckBoxInputProps} from './CheckBoxInput.interface'

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  label,
  id,
  labelProps = {},
  checked,
  ...rest
}) => {
  return (
    <StyledCheckboxContainer htmlFor={id} {...labelProps}>
      <input type="checkbox" id={id} checked={checked} {...rest} />
      <div className="checkbox">
        <span className="checkmark"></span>
      </div>
      <Typography
        variant={TypographyVariants.SUBHEADING_REGULAR}
        customClassName="label"
        data-checked={checked}
      >
        {label}
      </Typography>
    </StyledCheckboxContainer>
  )
}

export default CheckBoxInput
