import React, {useCallback, useMemo, useState} from 'react'

import {StyledFilterChip} from '../CategoryFilters.styles'
import {ArrowHead} from 'src/components/shared/src/ComponentsV2/common/Arrows/ArrowHead'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {FilterChipProps, Option} from './FilterChip.interface'
import CloseIcon from 'src/components/shared/src/ComponentsV2/common/NavbarV2/Hamburger/CloseIcon'

const FilterChip: React.FC<FilterChipProps> = ({
  isSelected,
  isFilterApplied,
  appliedFilters,
  filter,
  onClick,
  ...rest
}) => {
  const {type = '', icon = ''} = filter || {}
  const [optionsMap] = useState(() => {
    const map: Record<string | number, Option> = {}
    filter.options.forEach((option: Option) => {
      map[option.value] = option
    })
    return map
  })

  const chipLabel = useMemo(() => {
    if (appliedFilters[filter.key] && filter.type === 'single') {
      return optionsMap[appliedFilters[filter.key] as string]?.label
    }

    if (appliedFilters[filter.key] && filter.type !== 'single') {
      return `${filter.label}`
    }
    return filter.label
  }, [appliedFilters, filter.key, filter.label, filter.type, optionsMap])

  const chipCount = useMemo(() => {
    if (filter.type === 'tag') return false
    if (appliedFilters[filter.key] && filter.type !== 'single') {
      return `${(appliedFilters[filter.key] as string[]).length}`
    }
  }, [appliedFilters, filter.key, filter.type])

  const isFilterTypeTag = useMemo(() => type === 'tag', [type])

  return (
    <StyledFilterChip isSelected={isFilterApplied} onClick={onClick} {...rest}>
      {icon && <OptimizedImage source={icon} customClassName="icon" />}
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="chip-label"
      >
        {chipLabel}
      </Typography>
      {chipCount && (
        <div className="chip-count-container">
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="chip-count"
          >
            {chipCount}
          </Typography>
        </div>
      )}
      {isFilterTypeTag && isFilterApplied && (
        <CloseIcon
          height={14}
          width={14}
          fontColor="var(--dls-brand-primary-color-500)"
          strokeWidth={2}
        />
      )}
      {!isFilterTypeTag && (
        <ArrowHead
          direction={isSelected ? 'up' : 'down'}
          isSelected={isFilterApplied}
          color={'var(--dls-secondary-text-colors)'}
          borderWidth={'1.5px'}
        />
      )}
    </StyledFilterChip>
  )
}

export default FilterChip
