import styled from 'styled-components'

export const StyledCheckboxContainer = styled.label`
  /* The container */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkbox {
    position: relative;
    height: 18px;
    width: 18px;
    border: 1.5px solid var(--dls-disabled-text-colors);
    border-radius: 4px;
    background: white;
  }

  .checkbox.checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background: white;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkbox {
    background-color: #f1f1f1;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkbox {
    background-color: var(--dls-category-primary-color-500);
    border: 1.5px solid var(--dls-category-primary-color-500);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkbox .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .label {
    color: var(--dls-secondary-text-colors);

    &[data-checked='true'] {
      color: var(--dls-brand-primary-color-500);
    }
  }

  @media screen and (min-width: 768px) {
    .label {
      font-size: 18px;
    }
  }
`
