import styled from 'styled-components'

export const CircleContainer = styled.div`
  position: relative;
  width: 42px;
  height: 42px;

  .product-image {
    width: var(--dls-size-32);
    height: var(--dls-size-32);
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-divider-light-color);
    position: absolute;
  }

  .image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle-background {
    fill: none;
    stroke: var(--brand-primary-white-color);
  }

  .circle-progress {
    fill: none;
    stroke: var(--dls-brand-primary-color-500);
    stroke-linecap: round;
    transition: stroke-dashoffset 0.4s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`
