import OptimizedImage from 'src/components/shared/src/ComponentsV2/common/OptimizedImage'
import {CircleContainer} from './CircularProgressBar.styles'

const size = 42
const strokeWidth = 6
const radius = (size - strokeWidth) / 2
const circumference = 2 * Math.PI * radius

const CircularProgressBar = ({
  progress,
  image,
}: {
  progress: number
  image: string
}) => {
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <CircleContainer>
      <svg width="100%" height="100%" viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          className="circle-background"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          className="circle-progress"
        />
      </svg>
      <div className="image-container">
        <OptimizedImage
          source={image}
          aspectWidth="20"
          aspectHeight="20"
          altText="Progress icon"
          loading="lazy"
          desktopViewWidth="FULL"
          mobileViewWidth="FULL"
        />
      </div>
    </CircleContainer>
  )
}

export default CircularProgressBar
