import styled from 'styled-components'

export const StyledLoader = styled.div`
  margin-bottom: var(--dls-size-16);
  padding: var(--dls-size-16);
  display: flex;
  gap: var(--dls-size-16);
  justify-content: center;

  .loading-card {
    height: 30vh;
    width: 35vw;
    border-radius: var(--dls-size-16);
    margin-bottom: var(--dls-size-12);
  }
  .shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`
