import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useGenericCtaV2} from 'src/hooks'
import {
  MilestoneNudgeActions,
  MilestoneNudgeState,
  MilestoneTier,
} from './MilestoneNudge.interface'

const useMilestoneNudge = (
  milestoneTier: MilestoneTier
): [MilestoneNudgeState, MilestoneNudgeActions] => {
  const {
    progress = 0,
    progressIcon = '',
    discountText = '',
    productImage = '',
    title = '',
    subtitle = '',
    cta,
    state = 'LOCKED',
    value = 0,
    cartSubTotal = 0,
    unlockedLottie = '',
  } = milestoneTier || {}

  const {label = '', action = '', actionData = {}} = cta || {}

  const prevMilestoneState = useRef(state)
  const [showConfetti, setShowConfetti] = useState(false)
  const {handleCtaV2} = useGenericCtaV2()
  const confettiContainerRef = useRef<HTMLDivElement | null>(null)

  const handleCtaClick = useCallback(() => {
    handleCtaV2({
      action,
      data: {
        ...(actionData || {}),
      },
    })

    analyticsTrigger(EVENT_MAP.CTA_CLICKED, {
      source: 'shop',
      label,
    })
  }, [action, actionData, handleCtaV2, label])

  useEffect(() => {
    if (prevMilestoneState.current === 'LOCKED' && state === 'UNLOCKED') {
      setShowConfetti(true)
      const timeoutId = setTimeout(() => {
        setShowConfetti(false)
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    } else if (state === 'LOCKED') {
      setShowConfetti(false)
    }

    prevMilestoneState.current = state
  }, [state])

  useEffect(() => {
    if (prevMilestoneState.current === 'LOCKED' && state === 'UNLOCKED') {
      analyticsTrigger(EVENT_MAP.UPSELL_MILESTONE_UNLOCKED, {
        'Tier Value': value,
        'Cart Total': cartSubTotal,
        source: 'shop',
      })
    }
  }, [cartSubTotal, state, value])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        confettiContainerRef.current &&
        confettiContainerRef.current.contains(event.target as Node)
      ) {
        setShowConfetti(false)
      }
    }

    if (showConfetti) {
      confettiContainerRef.current?.addEventListener('click', handleClick)
    }

    return () => {
      confettiContainerRef.current?.removeEventListener('click', handleClick)
    }
  }, [showConfetti])

  const milestoneState = useMemo(() => {
    return {
      progress,
      progressIcon,
      discountText,
      productImage,
      title,
      subtitle,
      label,
      state,
      showConfetti,
      unlockedLottie,
      confettiContainerRef,
    }
  }, [
    discountText,
    progressIcon,
    label,
    productImage,
    progress,
    showConfetti,
    state,
    subtitle,
    title,
    unlockedLottie,
  ])

  const milestoneActions = useMemo(() => {
    return {handleCtaClick}
  }, [handleCtaClick])

  return [milestoneState, milestoneActions]
}

export default useMilestoneNudge
