import {useState, useCallback, useMemo, useEffect, useRef} from 'react'
import {useRouter} from 'next/router'
import isEmpty from 'lodash/isEmpty'
import {
  ShopProps,
  Category,
  ProductsActions,
  ProductsState,
} from './Shop.interface'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {logError} from 'src/utils/logger'
import {useFilterContext} from './FilterContext'
import {
  useCartStore,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {useFetch} from 'src/hooks/api/useFetch'
import {useInnerHeight} from 'src/hooks'

export const useShop = (
  pageData: ShopProps['pageData']
): [ProductsState, ProductsActions] => {
  const {data: allProductsDataProps} = pageData || {}
  const {
    categories = [],
    subcategoryBackButton,
    subcategoryAllCategoryButton,
  } = allProductsDataProps || {}
  const {
    icon: subcategoryBackButtonIcon = 'https://i.mscwlns.co/media/misc/others/back_3vq5zw.png?tr=w-600',
    label: subcategoryBackButtonLabel = 'View All',
  } = subcategoryBackButton || {}
  const {
    icon: subcategoryAllCategoryButtonIcon = 'https://i.mscwlns.co/media/misc/others/back_3vq5zw.png?tr=w-600',
    label: subcategoryAllCategoryButtonLabel = 'View All',
  } = subcategoryAllCategoryButton || {}
  const router = useRouter()
  const {category} = router.query || {}
  const categoryName = category?.[0] || 'all'
  const subcategoryName = category?.[1] || ''
  const {setFilters, filters} = useFilterContext()

  const {user} = useUserStore()

  const {isUserHydration} = useLoadingStore()
  const {isLoggedIn} = user
  const isUserLoggedInRef = useRef<null | boolean>(null)
  const currentUserStatusRef = useRef(false)

  const {cart} = useCartStore()
  const {innerHeight} = useInnerHeight()
  const {checkoutInfo} = cart || {}
  const {milestoneTier} = checkoutInfo || {}
  const {enableMilestoneNudge = false} = milestoneTier || {}

  useEffect(() => {
    if (!isUserHydration && !currentUserStatusRef.current) {
      isUserLoggedInRef.current = isLoggedIn
      currentUserStatusRef.current = true
    }
  }, [
    isUserHydration,
    currentUserStatusRef.current,
    isLoggedIn,
    isUserLoggedInRef,
  ])

  useEffect(() => {
    if (
      currentUserStatusRef.current &&
      isUserLoggedInRef.current != isLoggedIn
    ) {
      router.replace(router.asPath)
    }
  }, [isLoggedIn, currentUserStatusRef, isUserLoggedInRef])

  const [hasPageCallout, setHasPageCallout] = useState(false)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(
    () => {
      const foundIndex = categories.findIndex(
        (cat) => cat.name === categoryName
      )
      if (foundIndex === -1) {
        return 0
      }
      return foundIndex
    }
  )
  const selectedCategory = useMemo(() => {
    return categories[selectedCategoryIndex] || categories[0]
  }, [categories, selectedCategoryIndex])

  const hasDynamicPageKey = useMemo(() => {
    return selectedCategory?.isDynamicPage || false
  }, [selectedCategory])

  const dynamicPageConfig = useMemo(() => {
    if (hasDynamicPageKey) {
      return selectedCategory?.dynamicPageConfig
    }
  }, [selectedCategory])

  const {isLoading, data: dynamicData}: any = useFetch({
    queryKey: 'shop-dynamic-page',
    url: dynamicPageConfig?.apiEndPoint || '',
    disabled: !hasDynamicPageKey,
    cacheTime: 30 * 1000,
  })

  const [selectedSubcategoryIndex, setSelectedSubcategoryIndex] =
    useState<number>(() => {
      const foundIndex = selectedCategory?.subcategories?.findIndex(
        (subcat) => subcat.key === subcategoryName
      )
      if (foundIndex === -1 || !foundIndex) {
        return 0
      }
      return foundIndex
    })
  const subcategories = useMemo(() => {
    return selectedCategory?.subcategories || []
  }, [selectedCategory])

  const isSubcategoryPage = useMemo(
    () => !isEmpty(subcategories),
    [subcategories]
  )
  const selectedSubcategory = useMemo(() => {
    return subcategories[selectedSubcategoryIndex] || subcategories[0]
  }, [selectedSubcategoryIndex, subcategories])

  const handleCategoryClick = useCallback(
    (category: Category, index: number) => {
      const {name = '', label = ''} = category || {}
      if (selectedCategory?.name === name) return
      const filtersString = JSON.stringify(filters)
      let newPath = router.pathname.replace('[[...category]]', name)

      if (!isEmpty(category?.subcategories)) {
        newPath = `${newPath}/${category.subcategories[0].key}`
      }
      analyticsTrigger(EVENT_MAP.CATEGORY_CLICKED, {
        source: 'shop category',
        label,
        link: newPath,
        category: name,
      })
      let query = {}
      if (filtersString !== '{}') {
        query = {...query, filter: filtersString}
      }

      router.push(
        {
          pathname: `${newPath}`,
          query,
        },
        undefined,
        {
          shallow: true,
        }
      )

      setSelectedCategoryIndex(index)
    },
    [filters, router, selectedCategory]
  )

  const handleSubcategoryClick = useCallback(
    (subcategory, index: number) => {
      const {key: newSubcategoryName = ''} = subcategory || {}
      if (subcategoryName === newSubcategoryName) return

      if (newSubcategoryName === 'back-button') {
        return router.push(
          router.pathname.replace('[[...category]]', `all`),
          undefined,
          {shallow: true}
        )
      }

      let newPath = router.pathname.replace(
        '[[...category]]',
        `${categoryName}/${newSubcategoryName}`
      )

      analyticsTrigger(EVENT_MAP.CATEGORY_CLICKED, {
        source: 'shop subcategory',
        subcategory: newSubcategoryName,
        link: newPath,
        category: categoryName,
      })

      router.replace(
        {
          pathname: newPath,
        },
        undefined,
        {
          shallow: true,
        }
      )
      setSelectedSubcategoryIndex(index - 1)
    },
    [categoryName, router, subcategoryName]
  )

  const setFiltersToURL = useCallback(
    (newFilters: Record<string, any>) => {
      const filtersString = JSON.stringify(newFilters)
      const basePath = router.pathname.replace('[[...category]]', '')
      if (categoryName)
        router.replace(
          {
            pathname: `${basePath}${categoryName}`,
            query: {filter: filtersString},
          },
          undefined,
          {shallow: true}
        )
    },
    [categoryName, router]
  )

  const setFiltersInStoreFromUrl = useCallback(() => {
    const {filter: filtersParam} = router.query
    if (typeof filtersParam === 'string') {
      try {
        const parsedFilters = JSON.parse(filtersParam)
        setFilters(parsedFilters)
      } catch (error) {
        logError(error as Error, {
          location: 'shop',
        })
        setFilters({})
      }
    }
  }, [router.query, setFilters])

  const widgetsToRender = useMemo(
    () => selectedCategory?.widgets || [],
    [selectedCategory?.widgets]
  )
  const selectedCategoryFilters = useMemo(
    () => selectedCategory?.filters || [],
    [selectedCategory]
  )

  const categoryMetaData = useMemo(
    () => selectedCategory?.meta || {},
    [selectedCategory]
  )

  const showFilters = useMemo(
    () => !isEmpty(selectedCategoryFilters) && isEmpty(subcategories),
    [selectedCategoryFilters, subcategories]
  )
  useEffect(() => {
    setFiltersInStoreFromUrl()
  }, [])

  const onFilterApply = useCallback(
    (key: string, newFilters: Record<string, any>) => {
      setFilters(newFilters)
      setFiltersToURL(newFilters)
      if (newFilters[key]) {
        analyticsTrigger(EVENT_MAP.PRODUCT_FILTER_CLICKED, {
          filterName: key,
          optionsSelected: newFilters[key],
          url: window.location?.href ?? '',
          source: 'shop',
        })
      }
    },
    [setFilters, setFiltersToURL]
  )

  const filterProducts = useCallback(
    (
      products: Record<string, any>[],
      appliedFilters: Record<string, any[]>
    ) => {
      // Create a map of selected category filter keys for quicker access
      const filterKeyMap = new Set(
        selectedCategoryFilters.map((filter) => filter.key)
      )

      const relevantFilters = Object.fromEntries(
        Object.entries(appliedFilters).filter(([filterKey]) =>
          filterKeyMap.has(filterKey)
        )
      )
      return products.filter((product: Record<string, any>) => {
        // For each product, check every applied filter
        return Object.entries(relevantFilters).every(([, filterValues]) => {
          // Check if any of the filter values are present in the product tags
          return filterValues.some((value) => product.tags.includes(value))
        })
      })
    },
    [selectedCategoryFilters]
  )

  const createFilteredWidget = useCallback(
    (widget: BaseWidget, filteredProducts: Record<string, any>[]) => {
      return {
        ...widget,
        widgetData: {
          ...widget.widgetData,
          products: filteredProducts,
        },
      }
    },
    []
  )

  const filterWidgets = useCallback(
    (widgets: BaseWidget[], appliedFilters: Record<string, any[]>) => {
      return widgets.map((widget) => {
        if (widget.type !== 'CATEGORY_PRODUCT_CARD_GRID') {
          return widget
        }

        const filteredProducts = filterProducts(
          widget.widgetData?.products,
          appliedFilters
        )

        return createFilteredWidget(widget, filteredProducts)
      })
    },
    [createFilteredWidget, filterProducts]
  )

  const updatedWidgetsToRender = useMemo(() => {
    return showFilters
      ? filterWidgets(widgetsToRender, filters)
      : widgetsToRender
  }, [filterWidgets, filters, showFilters, widgetsToRender])

  const filterWidgetsForSubcategory = useCallback(
    (widgets: BaseWidget[]) => {
      const value = selectedSubcategory?.key || ''
      if (!value) return widgets
      return widgets
        .filter((widget) => {
          return widget.type === 'CATEGORY_PRODUCT_CARD_GRID'
        })
        .map((widget) => {
          const categoryProducts = widget.widgetData?.products || []
          const filteredProducts = value
            ? categoryProducts.filter((product: Record<string, any>) => {
                return product.tags.includes(value)
              })
            : categoryProducts
          return createFilteredWidget(widget, filteredProducts)
        })
    },
    [createFilteredWidget, selectedSubcategory]
  )

  const subcategoryWidgetsToRender = useMemo(
    () => filterWidgetsForSubcategory(widgetsToRender),
    [filterWidgetsForSubcategory, widgetsToRender]
  )

  const subcategoriesWithBackButton = useMemo(
    () => [
      {
        label: subcategoryBackButtonLabel,
        icon: subcategoryBackButtonIcon,
        key: 'back-button',
      },
      ...subcategories,
      {
        ...subcategoryAllCategoryButton,
        label: subcategoryAllCategoryButtonLabel,
        icon: subcategoryAllCategoryButtonIcon,
        key: 'back-button',
      },
    ],
    [
      subcategoryBackButtonLabel,
      subcategoryBackButtonIcon,
      subcategories,
      subcategoryAllCategoryButton,
      subcategoryAllCategoryButtonLabel,
      subcategoryAllCategoryButtonIcon,
    ]
  )

  const leftPaneCategories = useMemo(
    () => (isSubcategoryPage ? subcategoriesWithBackButton : categories),
    [categories, isSubcategoryPage, subcategoriesWithBackButton]
  )

  const selectedItemKey = useMemo(
    () =>
      isSubcategoryPage ? selectedSubcategory?.key : selectedCategory?.name,
    [isSubcategoryPage, selectedCategory?.name, selectedSubcategory?.key]
  )

  const compareKey = useMemo(
    () => (isSubcategoryPage ? 'key' : 'name'),
    [isSubcategoryPage]
  )

  const finalWidgetsToRender = useMemo(() => {
    if (hasDynamicPageKey && dynamicData)
      return dynamicData?.data?.data?.widgets || []

    return isSubcategoryPage
      ? subcategoryWidgetsToRender
      : updatedWidgetsToRender
  }, [
    isSubcategoryPage,
    subcategoryWidgetsToRender,
    updatedWidgetsToRender,
    hasDynamicPageKey,
    dynamicData,
  ])

  const handleOnClick = useCallback(
    (category, index) => {
      isSubcategoryPage
        ? handleSubcategoryClick(category, index)
        : handleCategoryClick(category, index)
    },
    [handleCategoryClick, handleSubcategoryClick, isSubcategoryPage]
  )
  useEffect(() => {
    const checkForPageCallout = () => {
      const calloutElement = document.querySelector('.page-call-out')
      setHasPageCallout(!!calloutElement)
    }
    const hideFooter = () => {
      const footer = document.getElementById('website-footer')
      if (footer) footer.style.display = 'none'
    }
    if (!!document) {
      checkForPageCallout()
      hideFooter()
    }

    const observer = new MutationObserver(checkForPageCallout)
    observer.observe(document.body, {childList: true, subtree: true})

    return () => observer.disconnect()
  }, [])

  const state = useMemo(() => {
    return {
      showFilters,
      selectedCategoryFilters,
      categoryMetaData,
      leftPaneCategories,
      compareKey,
      selectedItemKey,
      finalWidgetsToRender,
      milestoneTier,
      showMilestoneNudge: enableMilestoneNudge,
      isPageLoading: isLoading && hasDynamicPageKey,
      innerHeight,
      hasPageCallout,
    }
  }, [
    showFilters,
    selectedCategoryFilters,
    categoryMetaData,
    leftPaneCategories,
    compareKey,
    selectedItemKey,
    finalWidgetsToRender,
    milestoneTier,
    enableMilestoneNudge,
    innerHeight,
    hasPageCallout,
    isLoading,
    hasDynamicPageKey,
  ])

  const actions = useMemo(() => {
    return {
      onFilterApply,
      setFiltersInStoreFromUrl,
      handleOnClick,
    }
  }, [handleOnClick, onFilterApply, setFiltersInStoreFromUrl])

  return [state, actions]
}
