import {useCallback, useState} from 'react'
import noop from 'lodash/noop'

import FilterChip from './FilterChip/FilterChip'
import FilterSelectorMobile from './FilterSelectorMobile'
import useFilters from './useFilters'
import Responsive from 'src/components/shared/src/ComponentsV2/HOC/Responsive'
import {FiltersWrapper} from './CategoryFilters.styles'
import {Filter} from './CategoryFilters.interface'

const CategoryFilters = ({filters = [], onFilterApply = noop}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [currentSelectedFilterType, setCurrentSelectedFilterType] =
    useState<Filter | null>(null)
  const [{appliedFilters}, {applyFilter}] = useFilters({
    onFilterApply,
    filters,
  })

  const handleFilterTypeClick = useCallback(
    (filter: Filter) => {
      setCurrentSelectedFilterType(currentSelectedFilterType ? null : filter)

      if (filter.type === 'tag') {
        const isFilterApplied = !!appliedFilters[filter.key]
        if (isFilterApplied) {
          return applyFilter(filter.key, [])
        }

        const values = filter.options.map(({value}: {value: string}) => value)
        applyFilter(filter.key, values)
      } else {
        setIsSelectorOpen(!isSelectorOpen)
      }
    },
    [appliedFilters, applyFilter, currentSelectedFilterType, isSelectorOpen]
  )

  const handleModalClose = useCallback(() => {
    setCurrentSelectedFilterType(null)
    setIsSelectorOpen(false)
  }, [])

  return (
    <FiltersWrapper>
      <div className="category-filters">
        {filters.map((filter: Filter, index: number) => {
          return (
            <div className="filter" key={index.toString()}>
              <FilterChip
                isSelected={currentSelectedFilterType?.key === filter.key}
                isFilterApplied={!!appliedFilters[filter.key]}
                appliedFilters={appliedFilters}
                filter={filter}
                onClick={() => handleFilterTypeClick(filter)}
              />
            </div>
          )
        })}
      </div>

      {currentSelectedFilterType && (
        <FilterSelectorMobile
          isModalOpen={isSelectorOpen}
          filterSelected={currentSelectedFilterType}
          onClose={handleModalClose}
          applyFilter={applyFilter}
          appliedFilters={appliedFilters}
        />
      )}
    </FiltersWrapper>
  )
}

export default Responsive(CategoryFilters)
