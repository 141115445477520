import styled from 'styled-components'

export const CategoryNavigationContainer = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--dls-size-8);
  padding-bottom: var(--dls-size-4);
  cursor: pointer;

  &[data-hasBackground='true'] {
    background: var(--dls-warning-color-100);
  }
  .horizontal-divider {
    width: var(--dls-size-56);
    height: var(--dls-size-1);
    margin-bottom: var(--dls-size-12);
    margin-top: var(--dls-size-4);
    background: var(--dls-divider-light-color);
  }

  .vertical-divider {
    width: 1.5px;
    background: var(--dls-warning-color-200);
    height: var(--dls-size-16);
  }

  .image-container {
    display: flex;
    flex-direction: column;
    width: var(--dls-size-56);
    height: var(--dls-size-56);
    overflow: hidden;
    justify-content: center;
  }

  .image {
    object-fit: contain;
  }

  .title {
    padding: var(--dls-size-4);
    padding-bottom: 0;
    text-align: center;
    width: 100%;
    color: var(--dls-secondary-text-colors);
  }

  .selectedTitle {
    color: var(--dls-brand-primary-color-500);
  }

  @media screen and (min-width: 768px) {
    .image-container {
      width: var(--dls-size-72);
      height: var(--dls-size-72);
    }
  }
`
