import noop from 'lodash/noop'
import {useCallback, useMemo} from 'react'
import {
  Filter,
  FilterActions,
  FilterState,
  UseFiltersProps,
} from './CategoryFilters.interface'
import {useFilterContext} from '../FilterContext'

const useFilters = ({
  onFilterApply = noop,
  filters = [],
}: UseFiltersProps): [FilterState, FilterActions] => {
  const {setFilters, filters: appliedFilters} = useFilterContext()

  const applyFilter = useCallback(
    (filterKey: string, filterValues: string[]) => {
      // Find the selected filter by key
      const selectedFilter = filters.find((filter) => filter.key === filterKey)
      const selectedFilterType = selectedFilter?.type || ''

      // Clone the current filter state and update the selected filter
      const updatedFilterState = {
        ...appliedFilters,
        [filterKey]: filterValues,
      }

      // Remove the filter if no values are selected
      if (filterValues.length === 0) {
        delete updatedFilterState[filterKey]
      }

      // If the selected filter is a 'tag', remove other tag-type filters
      if (selectedFilterType === 'tag') {
        filters
          .filter(
            (filter: Filter) =>
              filter.type === 'tag' && appliedFilters[filter.key]
          )
          .forEach((filter: Filter) => {
            delete updatedFilterState[filter.key]
          })
      }

      // Update the filter state and apply the filter
      setFilters(updatedFilterState)
      onFilterApply(filterKey, updatedFilterState)
    },
    [appliedFilters, filters, onFilterApply, setFilters]
  )

  const actions = useMemo(() => {
    return {applyFilter}
  }, [applyFilter])

  const state = useMemo(() => {
    return {appliedFilters: appliedFilters}
  }, [appliedFilters])

  return [state, actions]
}

export default useFilters
