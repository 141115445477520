import dynamic from 'next/dynamic'

export const LottiePlayer = dynamic(
  //@ts-ignore
  () => import('./LottiePlayer'),
  {
    ssr: false,
  }
)

export default LottiePlayer
