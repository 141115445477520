import {StyledMilestoneProgressBar} from './MilestoneProgressBar.styles'
import {Typography} from 'src/components/shared/src/ComponentsV2/common/TypographyV2'
import CircularProgressBar from './CircularProgressBar/CircularProgressBar'
import {MilestoneProgressBarProps} from './MilestoneProgressBar.interface'

const MilestoneProgressBar = (props: MilestoneProgressBarProps) => {
  const {progress = 0, progressIcon = '', discountText = ''} = props || {}

  return (
    <StyledMilestoneProgressBar>
      <div className="progress-container">
        <CircularProgressBar progress={progress} image={progressIcon} />
      </div>
      <div className="milestone-text-container">
        <Typography variant="tag-regular">
          <span
            dangerouslySetInnerHTML={{
              __html: discountText,
            }}
          />
        </Typography>
      </div>
    </StyledMilestoneProgressBar>
  )
}

export default MilestoneProgressBar
