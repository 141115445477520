import {Typography, TypographyVariants} from '@web-components'
import {StyledRadioContainer} from './RadioInput.styles'
import {RadioInputProps} from './RadioInput.interface'

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  id,
  labelProps = {},
  checked,
  ...rest
}) => {
  return (
    <StyledRadioContainer htmlFor={id} {...labelProps}>
      <input type="radio" id={id} checked={checked} {...rest} />
      <div className="radio">
        <span className="checkmark"></span>
      </div>
      <Typography
        variant={TypographyVariants.SUBHEADING_REGULAR}
        customClassName="label"
        data-checked={checked}
      >
        {label}
      </Typography>
    </StyledRadioContainer>
  )
}

export default RadioInput
