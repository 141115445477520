import {WidgetRenderer} from '@web-core'
import {ShopContainer} from './Shop.styles'
import {CategoryFilters} from './CategoryFilters'
import {ShopProps} from './Shop.interface'
import {useShop} from './useShop'
import ProductListSEO from 'src/components/SEO/ProductList'
import {CategoryNavigation} from './CategoryNavigation'
import MilestoneNudge from './MilestoneNudge'
import ShopLoadingShimmer from './ShopLoadingShimmer'

const Shop: React.FC<ShopProps> = ({pageData}) => {
  const [state, actions] = useShop(pageData)
  const {
    showFilters,
    selectedCategoryFilters,
    categoryMetaData,
    leftPaneCategories,
    compareKey,
    selectedItemKey,
    finalWidgetsToRender,
    milestoneTier,
    showMilestoneNudge,
    isPageLoading,
    innerHeight,
    hasPageCallout,
  } = state || {}
  const {onFilterApply, handleOnClick} = actions || {}

  return (
    <>
      <ProductListSEO meta={categoryMetaData} />
      <ShopContainer
        data-milestone={showMilestoneNudge}
        innerHeight={innerHeight}
        hasPageCallout={hasPageCallout}
      >
        <div className="category-container">
          {leftPaneCategories.map((item: any, index) => (
            <CategoryNavigation
              key={item[compareKey]}
              category={item}
              isSelected={item[compareKey] === selectedItemKey}
              handleCategoryClick={(category) => handleOnClick(category, index)}
            />
          ))}
        </div>
        <div className="page-container">
          {showFilters && (
            <CategoryFilters
              filters={selectedCategoryFilters}
              onFilterApply={onFilterApply}
            />
          )}
          {isPageLoading ? (
            <ShopLoadingShimmer />
          ) : (
            <WidgetRenderer widgets={finalWidgetsToRender} />
          )}
        </div>
      </ShopContainer>
      {showMilestoneNudge && <MilestoneNudge milestoneTier={milestoneTier} />}
    </>
  )
}

export default Shop
