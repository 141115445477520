import React from 'react'
import {StyledLoader} from './LoadingShimmer.styles'

const ShopLoadingShimmer = () => {
  return (
    <StyledLoader>
      <div>
        <div className="loading-card shimmer" />
        <div className="loading-card shimmer" />
      </div>
      <div>
        <div className="loading-card shimmer" />
        <div className="loading-card shimmer" />
      </div>
    </StyledLoader>
  )
}

export default ShopLoadingShimmer
