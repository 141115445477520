import {MilestoneNudgeContainer} from './MilestoneNudge.styles'
import OptimizedImage from 'src/components/shared/src/ComponentsV2/common/OptimizedImage'
import {Typography} from 'src/components/shared/src/ComponentsV2/common/TypographyV2'
import {Button} from '@web-components'
import LottiePlayer from 'src/components/shared/src/ComponentsV2/common/LottiePlayer'
import {useGenericCtaV2} from 'src/hooks'
import useMilestoneNudge from './useMilestoneNudge'
import {MilestoneTier} from './MilestoneNudge.interface'
import {useRef} from 'react'
import MilestoneProgressBar from './MilestoneProgressBar'

const MilestoneNudge = ({milestoneTier}: {milestoneTier: MilestoneTier}) => {
  const [milestoneState, milestoneActions] = useMilestoneNudge(milestoneTier)
  const {handleCtaClick} = milestoneActions || {}
  const {
    showConfetti = false,
    label = '',
    progress = 0,
    progressIcon = '',
    discountText = '',
    productImage = '',
    title = '',
    subtitle = '',
    unlockedLottie = '',
    confettiContainerRef,
  } = milestoneState || {}

  return (
    <MilestoneNudgeContainer ref={confettiContainerRef}>
      {showConfetti ? (
        <LottiePlayer
          src={unlockedLottie}
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: 5,
          }}
        />
      ) : null}
      <MilestoneProgressBar
        discountText={discountText}
        progressIcon={progressIcon}
        progress={progress}
      />
      <div className="cart-container">
        <OptimizedImage
          source={productImage}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText="category-image"
          loading="lazy"
          customClassName="product-image"
        />
        <div className="cart-details">
          <Typography variant="body-base-bold">{title}</Typography>
          {subtitle && (
            <Typography variant="x-small" customClassName="cart-subtitle">
              {subtitle}
            </Typography>
          )}
        </div>
        <Button
          variant="PRIMARY"
          type="button"
          size="LARGE"
          customClassName="primary-btn-container"
          customLabelClassName="primary-btn-label"
          onClick={handleCtaClick}
          label={label}
        />
      </div>
    </MilestoneNudgeContainer>
  )
}

export default MilestoneNudge
