import noop from 'lodash/noop'
import {createContext, useContext, useState, ReactNode} from 'react'

interface Filters {
  [key: string]: string[]
}

interface FilterContextProps {
  filters: Filters
  setFilters: (newFilters: Filters) => void
}

interface FilterProviderProps {
  children: ReactNode
  initialFilters?: Filters
}

const FilterContext = createContext<FilterContextProps>({
  filters: {},
  setFilters: noop,
})

export const FilterProvider = ({
  children,
  initialFilters = {},
}: FilterProviderProps) => {
  const [filters, setFiltersState] = useState<Filters>(initialFilters)

  const setFilters = (newFilters: Filters) => {
    setFiltersState(newFilters)
  }

  return (
    <FilterContext.Provider value={{filters, setFilters}}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => {
  const context = useContext(FilterContext)

  return context
}
