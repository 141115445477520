import {TypographyVariants} from '@web-components'
import React, {useMemo} from 'react'
import OptimizedImage from 'src/components/shared/src/ComponentsV2/common/OptimizedImage'
import {Typography} from 'src/components/shared/src/ComponentsV2/common/TypographyV2'
import styled from 'styled-components'
import {CategoryNavigationContainer} from './CategoryNavigation.styles'
import {Category, CategoryNavigationProps} from '../Shop.interface'

const CategoryNavigation: React.FC<CategoryNavigationProps> = ({
  handleCategoryClick = () => {},
  category,
  isSelected = false,
}) => {
  const {
    label = '',
    icon = '',
    selectedIcon = '',
    slugUrl = '',
    hasHorizontalDivider = false,
    hasBackground = false,
    hasVerticalDivider = false,
  } = category || {}

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    handleCategoryClick(category)
  }

  return (
    <CategoryNavigationContainer
      href={slugUrl}
      onClick={handleOnClick}
      data-hasBackground={hasBackground}
    >
      {hasHorizontalDivider && <div className="horizontal-divider" />}
      <div className="image-container">
        <OptimizedImage
          source={isSelected && selectedIcon ? selectedIcon : icon}
          desktopViewWidth="SMALL"
          mobileViewWidth="SMALL"
          altText="category-image"
          loading="lazy"
          customClassName="image"
        />
      </div>
      <Typography
        variant={
          isSelected
            ? TypographyVariants.TAG_BOLD
            : TypographyVariants.TAG_REGULAR
        }
        customClassName={['title', isSelected && 'selectedTitle'].join(' ')}
      >
        {label}
      </Typography>
      {hasVerticalDivider && <div className="vertical-divider" />}
    </CategoryNavigationContainer>
  )
}

export default CategoryNavigation
