import styled from 'styled-components'

export const MilestoneNudgeContainer = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 3;
  box-shadow: 0px 12px 20px 20px #00000055;

  .cart-container {
    background-color: var(--brand-primary-white-color);
    padding: var(--dls-size-16);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .product-image {
    width: var(--dls-size-40);
    height: var(--dls-size-40);
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-divider-light-color);
  }

  .cart-details {
    margin-left: var(--dls-size-8);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .cart-subtitle {
    color: var(--dls-brand-primary-color-500);
  }

  .primary-btn-container {
    margin-left: var(--dls-size-8);
  }

  .primary-btn-label {
    white-space: nowrap;
  }

  @media screen and (min-width: 768px) {
    .cart-container {
      padding-left: var(--dls-size-96);
      padding-right: var(--dls-size-96);
    }
  }
`
