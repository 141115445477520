import {useCallback, useEffect, useMemo, useState} from 'react'
import {
  StyledFilterModalHeader,
  StyledFilterModalContainer,
  StyledFilterModalFooter,
  StyledOption,
  FilterModalStyle,
} from './CategoryFilters.styles'
import CheckBoxInput from './CheckBoxInput'
import RadioInput from './RadioInput'
import PortalModal from 'src/components/shared/src/ComponentsV2/common/Modal/PortalModal'
import {Button, Typography, TypographyVariants} from '@web-components'
import CloseIcon from './close-icon.svg'

const isFilterSelectionChanged = (
  selectedOptions: string[] = [],
  appliedOptions: string[] = []
) => {
  if (selectedOptions.length !== appliedOptions.length) {
    return true
  }

  const sortedSelectedOptions = [...selectedOptions].sort()
  const sortedAppliedOptions = [...appliedOptions].sort()

  for (let i = 0; i < sortedSelectedOptions.length; i++) {
    if (sortedSelectedOptions[i] !== sortedAppliedOptions[i]) {
      return true
    }
  }

  return false
}

const FilterSelectorMobile = ({
  isModalOpen,
  onClose,
  filterSelected,
  applyFilter,
  appliedFilters,
  primaryCtaLabel = 'Apply Filter',
  secondaryCtaLabel = 'Clear All',
}: any) => {
  const {options, type: selectionType, key, label} = filterSelected

  const [selectedOptions, setSelectedOptions] = useState(
    appliedFilters[key] ?? []
  )

  const isClearButtonEnabled = useMemo(
    () => selectedOptions.length !== 0,
    [selectedOptions.length]
  )

  const isApplyButtonEnabled = useMemo(
    () => isFilterSelectionChanged(selectedOptions, appliedFilters[key]),
    [appliedFilters, key, selectedOptions]
  )

  const handleCheckboxChange = useCallback(
    (event: any) => {
      const item = event.target.value
      const checked = event.target.checked
      if (checked) {
        setSelectedOptions([...selectedOptions, item])
      } else {
        setSelectedOptions(
          selectedOptions.filter((selectedItem: any) => selectedItem !== item)
        )
      }
    },
    [selectedOptions]
  )

  const handleRadioChange = useCallback((e: any) => {
    setSelectedOptions([e.target.value])
  }, [])

  const handleModalClose = useCallback(() => {
    setSelectedOptions(appliedFilters[key] ?? [])
    onClose()
  }, [appliedFilters, key, onClose])

  const handleApplyFilter = useCallback(() => {
    applyFilter(key, selectedOptions)
    handleModalClose()
  }, [applyFilter, handleModalClose, key, selectedOptions])

  const handleClearFilter = useCallback(() => {
    setSelectedOptions([])
  }, [])

  useEffect(() => {
    if (appliedFilters[key]?.length) {
      setSelectedOptions(appliedFilters[key])
    } else setSelectedOptions([])
  }, [appliedFilters, key])

  return (
    <StyledFilterModalContainer>
      <FilterModalStyle />
      <PortalModal
        showModal={isModalOpen}
        onClose={handleModalClose}
        additionalContentClass="filter-modal"
        isPadding={false}
        hideCloseButton
      >
        <StyledFilterModalHeader>
          <Typography
            variant={TypographyVariants.SUBHEADING_BOLD}
            customClassName="modal-header"
          >
            {label}
          </Typography>
          <div onClick={handleModalClose} className="close-icon-container">
            <img src={CloseIcon} loading="lazy" alt="close" />
          </div>
        </StyledFilterModalHeader>

        {options.map(({value, label}: any) => (
          <StyledOption key={label}>
            {selectionType === 'multiple' ? (
              <CheckBoxInput
                key={value}
                id={value}
                name="filter-options"
                onChange={handleCheckboxChange}
                value={value}
                label={label}
                checked={selectedOptions.includes(value)}
              />
            ) : (
              <RadioInput
                key={value}
                id={value}
                name="filter-options"
                onChange={handleRadioChange}
                value={value}
                label={label}
                checked={selectedOptions.includes(value)}
              />
            )}
          </StyledOption>
        ))}

        <StyledFilterModalFooter>
          <Button
            variant="SECONDARY"
            type="button"
            size="LARGE"
            customClassName="secondary-btn-container"
            customLabelClassName="secondary-btn-label"
            onClick={handleClearFilter}
            disabled={!isClearButtonEnabled}
            label={secondaryCtaLabel}
          />
          <Button
            variant="PRIMARY"
            type="button"
            size="LARGE"
            customClassName="primary-btn-container"
            customLabelClassName="primary-btn-label"
            onClick={handleApplyFilter}
            label={primaryCtaLabel}
            disabled={!isApplyButtonEnabled}
          />
        </StyledFilterModalFooter>
      </PortalModal>
    </StyledFilterModalContainer>
  )
}

export default FilterSelectorMobile
