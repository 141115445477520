import styled, {createGlobalStyle} from 'styled-components'

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  padding: var(--dls-size-16);
  border-bottom: 1px solid var(--dls-backgound-color-100);
  overflow-x: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .category-filters {
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1124px;
    gap: 8px;
    overflow-y: 'auto';
    .filter {
      position: relative;
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media screen and (min-width: 768px) {
    border: 0;
  }
`

export const StyledFilterChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  width: max-content;
  background-color: ${({isSelected}: {isSelected: boolean}) =>
    isSelected
      ? 'var(--dls-brand-primary-color-100)'
      : 'var(--brand-primary-white-color)'};
  border-radius: var(--dls-size-8);
  padding: var(--dls-size-8) var(--dls-size-12);
  border: ${({isSelected}) =>
    isSelected
      ? '1px solid var(--dls-brand-primary-color-500)'
      : '1px solid var(--dls-divider-light-color)'};
  cursor: pointer;

  .icon {
    width: 22px;
    height: 22px;
    background-color: var(--dls-backgound-color-100);
    border-radius: var(--dls-size-4);
    overflow: hidden;
  }

  .chip-label {
    color: ${({isSelected}) =>
      isSelected
        ? 'var(--dls-brand-primary-color-500)'
        : 'var(--dls-secondary-text-colors)'};
  }

  .chip-count-container {
    background-color: ${({isSelected}) =>
      isSelected
        ? 'var(--dls-brand-primary-color-500)'
        : 'var(--brand-primary-white-color)'};
    border-radius: var(--dls-size-16);
    color: ${({isSelected}) =>
      isSelected
        ? 'var(--brand-primary-white-color)'
        : 'var(--dls-primary-text-colors)'};

    height: var(--dls-size-16);
    width: var(--dls-size-16);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chip-count {
    line-height: 1;
  }

  @media screen and (min-width: 768px) {
    height: 42px;
    .chip-count-container {
      height: var(--dls-size-24);
      width: var(--dls-size-24);
    }
  }
`

export const StyledOption = styled.div`
  padding: 14px 16px;
  font-size: 14px;
`
export const StyledFilterModalHeader = styled.div`
  border-bottom: 1px solid var(--dls-divider-light-color);
  margin-bottom: 10px;
  padding: var(--dls-size-16);
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 900;
  justify-content: space-between;

  .close-icon-container {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 768px) {
    .modal-header {
      font-size: 18px;
    }
  }
`

export const StyledFiltersDropdownList = styled.div`
  min-width: 160px;
  position: absolute;
  list-style: none;
  padding: 0;
  top: 48px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05);
  background-color: var(--brand-primary-white-color);
  border-radius: 12px;
  overflow-y: visible;
  height: max-content;
  z-index: 1;
  .options-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
  }
`

export const StyledFilterModalContainer = styled.div`
  > * .filter-modal {
    padding: 0px;
  }
  > * .options-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
  }
`

export const StyledFilterModalFooter = styled.div`
  display: flex;
  border-top: 1px solid var(--dls-divider-light-color);
  padding: var(--dls-size-16);

  .secondary-btn-container {
    flex: 1;
    padding: 10px var(--dls-size-16);
    border: 1px solid var(--dls-divider-light-color);
    justify-content: center;
    align-items: baseline;
    border-radius: var(--dls-size-8);
    text-align: center;
    background-color: var(--brand-primary-white-color);
    margin-right: var(--dls-size-4);
    height: var(--dls-size-40);
    align-items: center;

    :disabled {
      opacity: 0.5;
    }
  }

  .secondary-btn-label {
    color: var(--brand-light-error-color);
    font-size: 14px;
  }

  .primary-btn-container {
    flex: 1;
    padding: 10px var(--dls-size-16);
    border: 1px solid #e3e3e3;
    justify-content: center;
    align-items: baseline;
    border-radius: var(--dls-size-8);
    text-align: center;
    background-color: var(--dls-brand-primary-color-500);
    margin-left: var(--dls-size-4);
    height: var(--dls-size-40);
    align-items: center;
  }

  .primary-btn-label {
    color: var(--brand-primary-white-color);
    font-size: 14px;
  }
`

export const FilterModalStyle = createGlobalStyle`

  @media screen and (min-width: 768px) {
    .filter-modal {
      padding: 0;
      min-width: 350px;
      width: 25%;
    }
  }
`
