import styled from 'styled-components'

export const StyledMilestoneProgressBar = styled.div`
  background-color: var(--dls-brand-secondary-color-200);
  padding: var(--dls-size-8);
  position: relative;
  margin-bottom: -0.5px;

  .progress-container {
    position: absolute;
    bottom: 0;
    left: var(--dls-size-12);
    background: var(--dls-brand-secondary-color-200);
    border-radius: 50%;
    border: 6px solid var(--dls-brand-secondary-color-200);
  }

  .milestone-text-container {
    margin-left: var(--dls-size-64);
  }

  @media screen and (min-width: 768px) {
    padding-left: var(--dls-size-96);
    padding-right: var(--dls-size-96);

    .progress-container {
      left: var(--dls-size-96);
    }
  }
`
